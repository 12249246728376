import axiosEx from '@/configs/http/external-api-axios'
import axiosIn from '@/configs/http/internal-api-axios'

import { convertToFormUrlencodedData } from '@/utils/object-helpers'
import get from 'lodash-es/get'
import store from '@/store/store'
import { saasCommonService } from '@/services/saas-service/saas-common-service'
import { MESSAGES } from '@/utils/messages'

const baseUrl = 'api/v1/service/slack'
let maxFailAllowSlack = 2

async function getAccessToken(formData) {
  const url = process.env.VUE_APP_SLACK_OAUTH_URL || 'https://slack.com/api/oauth.v2.access'
  const response = await axiosEx
    .post(url, convertToFormUrlencodedData(formData))
    .then((res) => {
      maxFailAllowSlack = 2
      return res
    })
    .catch(() => {
      if (maxFailAllowSlack-- === 0) {
        maxFailAllowSlack = 2
        return { data: false }
      }
      return getAccessToken(formData)
    })
  if (!response) {
    return false
  }
  return response.data
}

async function fetchListUser(subscribe_apps_id) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `${baseUrl}/${subscribe_apps_id}`
  return axiosIn
    .get(url)
    .then(async (res) => {
      return needRefreshToken(res.data, () => fetchListUser(subscribe_apps_id))
    })
    .catch((err) => {
      return err
    })
}

async function getListChannel() {
  return [
    { id: '1', name: 'チャンネル 1' },
    { id: '2', name: 'チャンネル 2' },
    { id: '3', name: 'チャンネル 3' },
    { id: '4', name: 'チャンネル 4' },
    { id: '5', name: 'チャンネル 5' },
    { id: '6', name: 'チャンネル 6' },
    { id: '7', name: 'チャンネル 7' },
    { id: '8', name: 'チャンネル 8' },
    { id: '9', name: 'チャンネル 9' },
    { id: '10', name: 'チャンネル 10' },
  ]
}

function getDialogState(isEnterprise) {
  /*
  true function can be used
  */
  return {
    ADD: false,
    CREATE: false,
    INVITE: isEnterprise,
    DELETE: false,
    FETCH: true,
  }
}

async function needRefreshToken(response, callback) {
  const errorMessage = get(response, 'error', '')
  if (
    errorMessage.indexOf('auth') !== -1 ||
    errorMessage.indexOf('internal_error') !== -1 ||
    errorMessage.indexOf('expired') !== -1
  ) {
    await store.dispatch('loading/showManualLoading')
    try {
      const savedMetadata = store.getters['saas/savedMetadata']
      const service = store.getters['saas/service']
      const isEnterprise = store.getters['saas/isEnterprise']
      const postData = buildFormData(isEnterprise, savedMetadata)
      // sonar warning here but it's ok
      const result = await getAccessToken(postData)
      if (!result) {
        await store.dispatch('loading/hideManualLoading')
        return {
          success: false,
          message: MESSAGES.COMMON.MSG15,
        }
      }
      if (result.ok) {
        const newMetadata = { ...savedMetadata, ...result }
        newMetadata.token_type = 'Bearer'
        await saasCommonService.addToken(service.id, newMetadata)
        await store.dispatch('saas/setServiceMetadata', newMetadata)
        await store.dispatch('loading/hideManualLoading')
        return callback()
      }
    } catch (e) {
      await store.dispatch('loading/hideManualLoading')
    }
  } else {
    return response
  }
}

function buildFormData(isEnterprise, savedMetadata) {
  if (isEnterprise) {
    return {
      client_id: process.env.VUE_APP_SLACK_E_CLIENT_ID,
      client_secret: process.env.VUE_APP_SLACK_E_CLIENT_SECRET,
      refresh_token: savedMetadata.refresh_token,
      grant_type: 'refresh_token',
    }
  }
  return {
    client_id: process.env.VUE_APP_SLACK_CLIENT_ID,
    client_secret: process.env.VUE_APP_SLACK_CLIENT_SECRET,
    refresh_token: savedMetadata.refresh_token,
    grant_type: 'refresh_token',
  }
}

export const slackService = {
  getListChannel,
  getDialogState,
  getAccessToken,
  fetchListUser,
}
