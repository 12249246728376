<template>
  <c-modal :id="dialogId" :title="dialogTitle" :buttons="dialogButtons">
    <div class="SlackInvite">
      <div class="SlackInvite__image">
        <img :src="logo || defaultCompanyLogo" alt="slack-image" />
      </div>
      <div class="SlackInvite__label font-size-12">チャンネル名</div>
      <div class="SlackInvite__multiselect">
        <m-select
          :value="value"
          :options="options"
          :multiple="true"
          :close-on-select="false"
          track-by="id"
          label="name"
        />
      </div>
    </div>
  </c-modal>
</template>
<script>
import CModal from '@/components/commons/common-modal'
import MSelect from '@/components/commons/common-multiselect'
import { slackService } from '@/services/saas-service/slack-service'
import { DEFAULT_COMPANY_LOGO } from '@/utils/constants'

export default {
  name: 'SlackInviteModal',
  components: { CModal, MSelect },
  props: {
    dialogId: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      default: '',
    },
    submitFunc: {
      type: Function,
      default: () => {
        return {}
      },
    },
    channelIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      value: [],
      options: [],
      dialogTitle: '連携するチャンネル選択',
      dialogButtons: [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '次へ',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.submitFunc,
        },
      ],
      defaultCompanyLogo: DEFAULT_COMPANY_LOGO,
    }
  },
  watch: {
    value(newVal) {
      this.$emit('update:channelIds', newVal)
    },
  },
  async created() {
    this.options = await slackService.getListChannel()
  },
}
</script>
<style lang="scss">
.SlackInvite {
  &__image {
    text-align: center;
    padding-top: 4px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 70px;
      max-height: 70px;
      border-radius: 10px;
    }
  }
  &__label {
    font-weight: 500;
    margin-bottom: 10px;
  }
}
</style>
