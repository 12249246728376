<template>
  <common-modal :id="dialogId" size="lg" title="連携" :empty-footer="true">
    <div class="SlackAccount">
      <div class="SlackAccount__logo">
        <img :src="logo || defaultCompanyLogo" alt="slack-logo" />
      </div>
      <div class="SlackAccount__button">
        <common-button
          size="md"
          title="無料アカウント連携"
          :func="handleConnectFreeAccount"
          :min-width="133"
        />
        <common-button
          size="md"
          title="有料アカウント連携"
          :func="handleConnectEnterpriseAccount"
          :min-width="133"
        />
      </div>
      <div class="SlackAccount__description">
        <div>※無料アカウントのため無料アカウントを選択してください 。</div>
        <div>※有料アカウントのため有料アカウントを選択してください 。</div>
      </div>
    </div>
  </common-modal>
</template>
<script>
import CommonModal from '@/components/commons/common-modal'
import CommonButton from '@/components/commons/common-button'
import { DEFAULT_COMPANY_LOGO } from '@/utils/constants'

export default {
  name: 'SlackChooseAccount',
  components: { CommonModal, CommonButton },
  props: {
    dialogId: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      default: '',
    },
    handleConnectFreeAccount: {
      type: Function,
      required: true,
    },
    handleConnectEnterpriseAccount: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      defaultCompanyLogo: DEFAULT_COMPANY_LOGO,
    }
  },
}
</script>
