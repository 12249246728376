<template>
  <Layout>
    <saas-layout
      v-if="dataReady"
      :page-titles="pageTitle"
      :bread-crumb-items="breadcrumbItems"
      :connected="connected"
    >
      <template #connection-info>
        <saas-information
          :src="service.logo || ''"
          :connected="connected"
          :usage="service.category_name"
          :connect-func="activeConnection"
          :disconnect-func="showDisconnectConnection"
          :connected-date="connectedDate"
          :connected-total="usedApp"
        />
        <!-- slack choose account type dialog-->
        <slack-connection-account
          :logo="service.logo"
          :dialog-id="dialogSlackAccountId"
          :handle-connect-free-account="handleConnectFreeAccountSlack"
          :handle-connect-enterprise-account="handleConnectEnterpriseAccount"
        />
      </template>
      <template #connected-content>
        <div class="SaaSConnection">
          <div class="SaaSConnection__select-time">
            <select-time :month.sync="month" :year.sync="year" />
          </div>
          <div class="SaaSConnection__list-account">
            <list-account
              :dialog-state="dialogState"
              :date="selectedDate"
              :fetch-data="fetchListUser"
              :show-invite-dialog="showInviteSlackDialog"
              :reload-table="reloadListAccountTable"
            />
          </div>
          <div class="SaaSConnection__contract-info">
            <contract-info :selected-date="selectedDate" />
          </div>
          <div class="SaaSConnection__expense-info">
            <expense-infomation-table
              :refresh="refreshContractTable"
              :selected-date="selectedDate"
            />
          </div>
          <disconnect-confirmation
            :id="disconnectDialogId"
            :confirm-disconnect-func="deactivateConnection"
          />
          <!-- enterprise account -->
          <slack-invite-modal
            v-if="isEnterprise"
            :logo="service.logo"
            :channel-ids.sync="channelIds"
            :dialog-id="dialogSlackInviteId"
            :submit-func="showInviteDialog"
          />
          <saas-invite-modal
            v-if="isEnterprise"
            :dialog-id="dialogInviteId"
            :is-invite="true"
            :reload="triggerReloadInvite"
            :reload-func="reloadFunc"
            :invite-func="inviteSlackUser"
          />
          <!-- enterprise account -->
        </div>
      </template>
    </saas-layout>
  </Layout>
</template>
<script>
import Layout from '@/layouts/main/app'
import SaasLayout from '@/components/commons/common-saas-layout'
import SaasInformation from '@/components/saas/saas-connection-info'
import { slackService } from '@/services/saas-service/slack-service'
import { saasCommonService } from '@/services/saas-service/saas-common-service'
import SelectTime from '@/components/saas/select-time'
import ListAccount from '@/components/tables/list-of-account-using-service-table'
import ContractInfo from '@/components/tables/contract-information-table'
import ExpenseInfomationTable from '@/components/tables/expense-infomation-table'
import SlackConnectionAccount from '@/components/modals/saas-slack-choose-account-modal'
import DisconnectConfirmation from '@/components/modals/saas-disconnect-confirm-modal'
import SlackInviteModal from '@/components/modals/slack-invite-modal'
import SaasInviteModal from '@/components/modals/saas-invite-connect-modal'
import { mapActions, mapGetters } from 'vuex'
import { stringToDateJapan } from '@/utils/date-utils'
import { ALERT_TYPE } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
import get from 'lodash-es/get'

export default {
  name: 'Slack',
  components: {
    Layout,
    SaasLayout,
    SaasInformation,
    ListAccount,
    SelectTime,
    ContractInfo,
    ExpenseInfomationTable,
    SlackConnectionAccount,
    DisconnectConfirmation,
    SlackInviteModal,
    SaasInviteModal,
  },
  data() {
    return {
      pageTitle: 'SLACK',
      serviceName: 'SLACK',
      breadcrumbItems: [
        {
          text: 'SaaS管理',
          href: '/customer/saas-management',
        },
        {
          text: '利用中のサービス',
          href: '/customer/saas-management',
        },
        {
          text: 'SLACK',
          active: true,
        },
      ],
      service: {},
      serviceId: '',
      dialogSlackAccountId: 'dialog-choose-account',
      disconnectDialogId: 'dialog-disconnect-confirm',
      dialogSlackInviteId: 'dialog-channels-select-slack',
      dialogInviteId: 'dialog-invite',
      connected: false,
      resetFlag: true,
      month: '',
      year: '',
      authorizedCode: '',
      savedMetadata: {},
      dataReady: false,
      refreshContractTable: false,
      reloadListAccountTable: false,
      channelIds: [],
      triggerReloadInvite: false,
      reloadFunc: saasCommonService.getListIntegrateUsers,
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['apps']),
    ...mapGetters('saas', ['code', 'isEnterprise']),
    connectedDate() {
      return stringToDateJapan(this.service.connect_time)
    },
    selectedDate() {
      if (this.month && this.year) {
        return `${this.month}-${this.year}`
      }
      return ''
    },
    enterpriseUser() {
      return get(this.savedMetadata, 'isEnterprise', false)
    },
    dialogState() {
      return slackService.getDialogState(this.enterpriseUser)
    },
    usedApp() {
      return this.$store.getters['saas/service'].member || 0
    },
  },
  async created() {
    this.serviceId = Number(this.$route.fullPath.split('/').pop())
    this.service = await saasCommonService.getDetailSaas(this.serviceId)
    this.setService(this.service)
    this.savedMetadata = await saasCommonService.getSavedMetadata(this.serviceId)
    this.setServiceMetadata(this.savedMetadata)
    this.connected = this.service.connect_time && this.service.is_active
    if (!this.connected) {
      this.resetFetchTime(this.serviceId)
    }
    this.dataReady = true
    if (!this.service) {
      await this.$router.push('/404')
    }
  },
  async mounted() {
    if (this.code) {
      this.authorizedCode = this.code
      this.resetState() // reset code received from external service
      this.resetFetchTime(this.serviceId)
    }
    if (this.authorizedCode) {
      await this.handleConnectSlack()
    }
  },
  methods: {
    ...mapActions('saas', [
      'setCallbackRoute',
      'resetState',
      'setService',
      'setServiceMetadata',
      'resetFetchTime',
      'setEnterprise',
    ]),
    ...mapActions('alert', ['displayAlert']),
    ...mapActions('loading', ['showManualLoading', 'hideManualLoading', 'enableLoading']),
    activeConnection() {
      this.$bvModal.show(this.dialogSlackAccountId)
    },
    async showDisconnectConnection() {
      this.$bvModal.show(this.disconnectDialogId)
    },
    async deactivateConnection() {
      // call api,auth
      const { success, message } = await saasCommonService.disconnectApp(this.service.id)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: message,
        })
        return
      }
      this.$bvModal.hide(this.disconnectDialogId)
      this.connected = false
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.SAAS_CONNECT.LK03,
      })
    },
    async handleConnectSlack() {
      // gen access token
      const postData = this.buildParams()
      this.enableLoading()
      this.showManualLoading()
      try {
        const result = await slackService.getAccessToken(postData)
        if (result.ok) {
          result.authed_user.token_type = 'Bearer'
          result.authed_user.auth_type = 'Authorization'
          result.authed_user.instance_url = process.env.VUE_APP_SLACK_INSTANCE_URL
          result.authed_user.isEnterprise = this.isEnterprise
          const res = await saasCommonService.connectApp(this.serviceId)
          if (!res.success) {
            this.displayAlert({
              type: ALERT_TYPE.ERROR,
              messages: res.messages || '',
            })
            return
          }
          await saasCommonService.addToken(this.serviceId, result.authed_user, true)
          this.service = await saasCommonService.getDetailSaas(this.serviceId, true)
          this.setService(this.service)
          this.savedMetadata = await saasCommonService.getSavedMetadata(this.serviceId, true)
          this.setServiceMetadata(this.savedMetadata)
          this.connected = true
          this.displayAlert({
            type: ALERT_TYPE.SUCCESS,
            messages: MESSAGES.SAAS_CONNECT.LK01,
          })
        } else {
          this.displayAlert({
            type: ALERT_TYPE.ERROR,
            messages: MESSAGES.COMMON.MSG15,
          })
        }
        this.hideManualLoading()
      } catch (e) {
        this.hideManualLoading()
      } finally {
        this.hideManualLoading()
      }
    },
    handleConnectFreeAccountSlack() {
      // save current path
      this.setCallbackRoute(this.$route.fullPath)
      this.setEnterprise(false)
      // get code from slack
      window.open(
        `${process.env.VUE_APP_SLACK_AUTHORIZE_URL}${process.env.VUE_APP_URL}/authorize`,
        '_self'
      )
    },
    handleConnectEnterpriseAccount() {
      // save current path
      this.setCallbackRoute(this.$route.fullPath)
      this.setEnterprise(true)
      // get code from slack
      window.open(
        `${process.env.VUE_APP_SLACK_E_AUTHORIZE_URL}${process.env.VUE_APP_URL}/authorize`,
        '_self'
      )
    },
    async fetchListUser() {
      const { success, message } = await slackService.fetchListUser(this.service.subscribe_apps_id)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: message,
        })
        return
      }
      this.refreshContractTable = !this.refreshContractTable
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.SAAS_CONNECT.LK04,
      })
    },
    buildParams() {
      if (this.isEnterprise) {
        return {
          client_id: process.env.VUE_APP_SLACK_E_CLIENT_ID,
          client_secret: process.env.VUE_APP_SLACK_E_CLIENT_SECRET,
          code: this.authorizedCode,
          grant_type: 'authorization_code',
          redirect_uri: `${process.env.VUE_APP_URL}/authorize`,
        }
      }
      return {
        client_id: process.env.VUE_APP_SLACK_CLIENT_ID,
        client_secret: process.env.VUE_APP_SLACK_CLIENT_SECRET,
        code: this.authorizedCode,
        grant_type: 'authorization_code',
        redirect_uri: `${process.env.VUE_APP_URL}/authorize`,
      }
    },
    showInviteSlackDialog() {
      this.$bvModal.show(this.dialogSlackInviteId)
    },
    showInviteDialog() {
      this.$bvModal.show(this.dialogInviteId)
      this.triggerReloadInvite = !this.triggerReloadInvite
    },
    inviteSlackUser(email) {
      // call API invites user by email
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.SAAS_CONNECT.LK07,
      })
      return {}
    },
  },
}
</script>
